.header{
    height: 80px;
    border-bottom: 1px solid rgb(179, 179, 179);
}

.logo {
    padding-top: 25px;
    float: left;
    padding-left: 30px;
}

.right-section {
    float: right;
    width: 180px;
    padding-top: 24px;
    text-align: center;
}

.user-info {
    float: right;
    padding-right: 30px;
}

.user-icon {
    float: left;
    margin-left: 25px;
    line-height: 1px;
}

.ms-graph-button{
    text-align: center;
    float: left;
    margin-left: 700px;
}
