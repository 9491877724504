#searchbox{
    display: flex;
}

form{
    width: 100%;
    display: flex;
    justify-content: center;
}

.buttonBox{
    display: flex;
}

.buttonBox:first-child button{
    margin-right: 8px;
}
