.unAuthorizedPage {
    text-align: center;
    margin-top: 60px;
    border-bottom: 1px solid rgb(179, 179, 179);
}

.skyHi-logo {
    text-align: center;
    margin-bottom: 48px;
    padding-left: 4px;
}

.unAuth-msg {
    font-size: 30px;
}

.lock-icon {
    font-size: x-large;
}

.SignOut-button {
    margin: 16px 0;
}