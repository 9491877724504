.footer{
    padding: 0px 0px 25px 0px;
}

.credits{
    text-align: center;
}

.line{
    width: 100%;
    border-bottom: 1px solid rgb(179, 179, 179);
    position: relative;
}
