.requests{
    display: flex;
    flex-flow: column;
    padding: 16px 24px;
    height: 800px;
    overflow: hidden;
}

.requestTitleBar {
    display: flex;
    justify-content: space-between;
}

.primary2{
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow-y: scroll;
}

.primary3{
    display: flex;
    flex-flow: column;
    height: 100%;
}

#boxContainer{
    height: 100%;
}