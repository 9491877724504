.factoryhub {
    padding: 25px;
    height: 800px;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    margin: 16px 0 18px;
}

.flexItem1 {
    flex: 50%;
    display: flex;
    align-items: center;
}

.flexItem2 {
    margin: auto;
    flex: 40%;
    text-align: left;
    padding: 10px;
}

.flexItem3 {
    flex: 10%;
    text-align: right;
    align-self: center;
}

.currentCommit {
    font-weight: 500;
    float: left;
    margin-right: 2px;
}

.commitID {
    font-weight: 400;
}