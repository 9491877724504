
.active {
    background: rgb(207, 232, 247);
    border-left: 8px solid rgb(46, 154, 216);
}

.navbar {
    text-decoration: none;
    text-align: center;
    border-right: 1px solid; 
    height: 100%;
    margin: 0px;
    width: 100%;
}

.link {
    text-decoration: none;
    font-size: 20px;
    color: black;
    height: 60px;
    padding-top: 15px;
}

.link:hover {
    background: rgb(207, 232, 247);
    color: white;
}
